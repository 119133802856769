import { PortableText } from '@portabletext/react';
import { useSmoothScrollTo } from 'ui/hooks';
import Image from 'next/image';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data?: {
    _type: string;
    bgColor: string;
    name: string;
    anchor?: string;
    headline?: string;
    subheadline?: [];
    ingredient?: {
      _key: string;
      label?: string;
      text?: string;
      icon?: {
        asset: {
          _type: string;
          _ref: string;
        };
        lqip: string;
      };
    }[];
  };
};

export default function Ingredients({ data }: Props): JSX.Element {
  const bind = useSmoothScrollTo(`#${data?.anchor}`);
  return (
    <section
      className={`flex flex-col w-full justify-center items-center py-8 sm:py-10 lg:py-20 px-6 md:px-10 lg:px-16 ${
        data?.bgColor ? data.bgColor : 'bg-white'
      }`}
      id={data?.anchor}
      {...(data?.anchor ? { ...bind } : null)}
    >
      <div className="flex w-full justify-center max-w-screen-md">
        <div className="flex gap-x-8 flex-col justify-center items-center w-full">
          <h3 className="w-full pb-2 md:pb-5 text-2xl lg:text-4xl text-primary font-bold leading-[1.875rem] lg:leading-10 text-left md:text-center">
            {data?.headline}
          </h3>
          <div className="pb-6 md:pb-8 text-sm md:text-base text-black-base text-left md:text-center">
            <PortableText value={data?.subheadline ?? []} />
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full justify-center max-w-screen-lg">
        <ul className="grid grid-rows-2 lg:grid-rows-1 grid-flow-col gap-4 sm:gap-10">
          {data?.ingredient?.map((ingredient) => (
            <li className="flex flex-col" key={ingredient._key}>
              <Image
                alt={ingredient.label || 'ingredient'}
                className="w-24 h-24 md:w-36 md:h-36 self-center"
                height={145}
                src={ingredient.icon ? urlForImage(ingredient.icon).url() : ''}
                width={145}
              />
              <span className="flex items-center text-sm md:text-base font-medium text-black-base text-center justify-center">
                {ingredient.label}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
