/* eslint-disable @typescript-eslint/no-misused-promises -- don't know why get this error in handleSubmit*/
'use client';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types';
import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined';
import Avatar from '@mui/material/Avatar';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import type { AlertColor } from '@mui/material/Alert';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
// eslint-disable-next-line import/no-named-as-default -- we should import like this
import ReCAPTCHA from 'react-google-recaptcha';
import type { TranslationsDictionary } from '@lib/i18n';
import { useTranslation } from '@lib/i18n';
import { handleContactForm } from '@lib/actions/contact';
import { ChatIcon, PhoneCallIcon, QuestionIcon, UserIcon } from '@public/icons';
import ContactSuccessMessage from './contact-success-message';

declare global {
  interface Window {
    recaptchaOptions?: { [key: string]: unknown };
  }
}

type Props = {
  data: {
    _type: string;
    bgColor: string;
    name: string;
    headline: string;
    subheadline: string;
    nameLabel: string;
    emailLabel: string;
    phoneLabel: string;
    contentLabel: string;
    tosLabel: string;
    ctaLabel: string;
    ctaEmail: string;
    tosContent: PortableTextBlock[];
    consumerTitle: string;
    consumerPhone: string;
    terapistTitle: string;
    terapistPhone: string;
    terapistWhatsapp: string;
    terapistEmail: string;
    faqTitle: string;
    faqCtaLabel: string;
    faqSlug: string;
  };
  translationsDictionary: TranslationsDictionary;
  locale: string;
  slug: string | string[];
};

export type ContactFormData = {
  name: string;
  email: string;
  phone: string;
  message: string;
  tos: boolean;
};

const textFieldProps = {
  input: { className: 'text-[0.875rem] leading-5' },
  label: { className: 'text-xs' },
};

export default function Contact({ data, translationsDictionary, locale, slug }: Props): JSX.Element {
  const fullPath = typeof slug === 'string' ? slug : slug.join('/');
  const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? '';
  const t = useTranslation(translationsDictionary);
  const [showAlert, setShowAlert] = useState({ show: false, type: 'success' });
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { register, handleSubmit, formState, reset } = useForm<ContactFormData>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      tos: false,
    },
  });
  const { errors } = formState;

  useEffect(() => {
    window.recaptchaOptions = {
      enterprise: true,
    };
  }, []);

  const onSubmit = async (contactData: ContactFormData): Promise<void> => {
    const recaptchaCode = await recaptchaRef.current?.executeAsync();

    const { type } = await handleContactForm(
      { localeId: locale, slugFilter: fullPath, token: recaptchaCode ?? undefined },
      contactData
    );

    if (type === 'error') {
      setShowAlert({ show: true, type: 'error' });
    }

    setShowAlert({ show: true, type: 'success' });
    reset();
  };

  return (
    <section className="flex flex-col w-full justify-center items-center py-6 lg:py-20">
      <div className="flex w-full justify-center px-6 md:px-10 lg:px-16 mb-10">
        <div className="flex gap-x-8 flex-col justify-center items-center w-full max-w-none lg:max-w-screen-md">
          <div className="flex flex-col sm:flex-row lg:flex-row gap-12">
            {showAlert.show && showAlert.type === 'success' ? (
              <ContactSuccessMessage translationsDictionary={translationsDictionary} />
            ) : (
              <div className="flex flex-col">
                <h5 className="text-xl text-primary leading-[1.625rem]">{data.headline}</h5>
                <p className="text-[0.875rem] leading-5 mt-2">{data.subheadline}</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaSiteKey} size="invisible" />
                  <div className="flex flex-col mt-4">
                    <TextField
                      className="mb-2 bg-accent-1"
                      id="filled-basic"
                      label={data.nameLabel}
                      variant="filled"
                      {...register('name', { required: t('contact.isRequired') })}
                      InputLabelProps={textFieldProps.label}
                      InputProps={textFieldProps.input}
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                    />
                    <TextField
                      className="mb-2 bg-accent-1"
                      id="filled-basic"
                      label={data.emailLabel}
                      variant="filled"
                      {...register('email', { required: t('contact.isRequired') })}
                      InputLabelProps={textFieldProps.label}
                      InputProps={textFieldProps.input}
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                    />
                    <TextField
                      className="mb-2 bg-accent-1"
                      id="filled-basic"
                      label={data.phoneLabel}
                      variant="filled"
                      {...register('phone', { required: t('contact.isRequired') })}
                      InputLabelProps={textFieldProps.label}
                      InputProps={textFieldProps.input}
                      error={Boolean(errors.phone)}
                      helperText={errors.phone?.message}
                    />
                    <TextField
                      className="mb-4 lg:mb-2 bg-accent-1"
                      id="filled-basic"
                      label={data.contentLabel}
                      minRows={4}
                      multiline
                      variant="filled"
                      {...register('message', { required: t('contact.isRequired') })}
                      InputLabelProps={textFieldProps.label}
                      InputProps={textFieldProps.input}
                      error={Boolean(errors.message)}
                      helperText={errors.message?.message}
                    />
                    <FormControl component="fieldset" error={Boolean(errors.tos)}>
                      <FormGroup className="mb-4 lg:mb-0">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color={errors.tos?.message ? 'error' : 'primary'}
                              inputProps={{ className: 'border border-[#ADADA9]' }}
                              sx={{
                                color: errors.tos?.message ? 'var(--mui-palette-error-main)' : '',
                              }}
                            />
                          }
                          label={data.tosLabel}
                          sx={{
                            color: errors.tos?.message ? 'var(--mui-palette-error-main)' : '',
                          }}
                          {...register('tos', { required: t('contact.isRequired') })}
                          classes={{ label: 'text-[0.75rem] leading-[1.125rem]' }}
                        />
                      </FormGroup>
                      <FormHelperText>{errors.tos?.message}</FormHelperText>
                    </FormControl>
                    <div className="mb-4 lg:mt-1">
                      <Button
                        className="w-full md:w-[14rem] text-sm font-medium"
                        color="primary"
                        disableElevation
                        type="submit"
                        variant="contained"
                      >
                        {data.ctaLabel}
                      </Button>
                    </div>
                    {showAlert.show ? (
                      <Alert icon={<CheckIcon fontSize="inherit" />} severity={showAlert.type as AlertColor}>
                        {showAlert.type === 'success' ? t('contact.success') : t('contact.error')}
                      </Alert>
                    ) : null}
                    <div className="mt-2 text-xxs text-[#797979] leading-4">
                      <PortableText value={data.tosContent} />
                    </div>
                  </div>
                </form>
              </div>
            )}
            <div className="flex flex-col gap-4">
              {data.consumerPhone ? (
                <div className="flex row min-w-none sm:min-w-[20rem] p-3 bg-[#FAFAFA] rounded-xl">
                  <Avatar className="bg-white text-primary w-12 h-12">
                    <PhoneCallIcon className="w-6 h-6" />
                  </Avatar>
                  <div className="flex flex-col ml-4">
                    <p className="text-[0.875rem] leading-5">{data.consumerTitle}</p>
                    <p className="flex flex-row gap-1 text-primary text-sm font-semibold mt-2">{data.consumerPhone}</p>
                  </div>
                </div>
              ) : null}
              {data.terapistEmail || data.terapistPhone || data.terapistWhatsapp ? (
                <div className="flex row min-w-none sm:min-w-[20rem] p-3 bg-[#FAFAFA] rounded-xl">
                  <Avatar className="bg-white text-primary w-12 h-12">
                    <UserIcon className="w-6 h-6" />
                  </Avatar>
                  <div className="flex flex-col ml-4">
                    <p className="text-[0.875rem] leading-5">{data.terapistTitle}</p>
                    {data.terapistPhone ? (
                      <p className="flex flex-row gap-1 text-primary text-sm font-semibold mt-2">
                        <PhoneCallIcon className="w-4 h-4 self-center" /> {data.terapistPhone}
                      </p>
                    ) : null}
                    {data.terapistWhatsapp ? (
                      <p className="flex flex-row gap-1 text-primary text-sm font-semibold mt-2">
                        <ChatIcon className="w-4 h-4 self-center" /> {data.terapistWhatsapp}
                      </p>
                    ) : null}
                    {data.terapistEmail ? (
                      <p className="flex flex-row gap-1 text-primary text-sm font-semibold mt-2">
                        <MailOutlineOutlinedIcon className="w-4 h-4 self-center" /> {data.terapistEmail}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {data.faqTitle && data.ctaLabel ? (
                <div className="flex row min-w-none sm:min-w-[20rem] p-3 bg-[#FAFAFA] rounded-xl">
                  <Avatar className="bg-white text-primary w-12 h-12">
                    <QuestionIcon className="w-6 h-6" />
                  </Avatar>
                  <div className="flex flex-col ml-4">
                    <p className="text-[0.875rem] leading-5">{data.faqTitle}</p>
                    <div>
                      <Button
                        className="px-5 py-2 text-[0.875rem] font-medium mt-2 leading-5"
                        color="primary"
                        disableElevation
                        href={data.faqSlug}
                        variant="contained"
                      >
                        {data.faqCtaLabel}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
