import Image from 'next/image';
import Button from '@mui/material/Button';
import { PortableText } from '@portabletext/react';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data: {
    _type: string;
    bgColor: string;
    headline: string;
    subheadline: [];
    ctaText: string;
    ctaHref: string;
    image: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
    variant: 'textFirst' | 'imageFirst';
  };
};

type ImageBlockProps = {
  headline: string;
  href: string;
  lqip: string;
};

function ImageBlock({ headline, href, lqip }: ImageBlockProps): JSX.Element {
  return (
    <div className="relative w-full h-[12.813rem] md:h-[24rem]">
      <Image
        alt={headline}
        blurDataURL={lqip}
        className="rounded-lg"
        fill
        placeholder="blur"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        src={href}
        style={{
          objectFit: 'cover',
        }}
      />
    </div>
  );
}

type TextBlockProps = {
  headline: string;
  subheadline: [];
  ctaText: string;
  ctaHref: string;
  variant: 'textFirst' | 'imageFirst';
};

function TextBlock({ headline, subheadline, ctaText, ctaHref, variant }: TextBlockProps): JSX.Element {
  return (
    <div
      className={`flex flex-col md:justify-center w-full order-1 sm:order-none ${variant === 'textFirst' ? 'pr-4' : 'pl-0 sm:pl-8 md:pl-16'}`}
    >
      <h3 className="pt-4 lg:pt-8 pb-[0.375rem] md:pb-[0.688rem] text-2xl lg:text-4xl text-primary font-bold leading-[1.875rem] lg:leading-10">
        {headline}
      </h3>
      <div className="text-sm md:text-base pb-[1.063rem] md:pb-[1.313rem] leading-6 text-black-base">
        <PortableText value={subheadline} />
      </div>
      {ctaText && ctaHref ? (
        <Button
          className="self-start text-base font-medium"
          color="secondary"
          disableElevation
          href={ctaHref}
          variant="contained"
        >
          {ctaText}
        </Button>
      ) : null}
    </div>
  );
}

export default function TextAndImage({ data }: Props): JSX.Element | null {
  if (!data.headline || !data.image.asset._ref) {
    return null;
  }

  return (
    <section
      className={`flex w-full justify-center px-6 md:px-10 lg:px-16 pb-8 lg:pb-[1.875rem] ${data.bgColor} text-image`}
    >
      <div className="flex flex-wrap justify-center items-center sm:flex-nowrap w-full max-w-screen-lg">
        {data.variant === 'textFirst' ? (
          <>
            <TextBlock
              ctaHref={data.ctaHref}
              ctaText={data.ctaText}
              headline={data.headline}
              subheadline={data.subheadline}
              variant={data.variant}
            />
            <ImageBlock headline={data.headline} href={urlForImage(data.image).url()} lqip={data.image.lqip} />
          </>
        ) : (
          <>
            <ImageBlock headline={data.headline} href={urlForImage(data.image).url()} lqip={data.image.lqip} />
            <TextBlock
              ctaHref={data.ctaHref}
              ctaText={data.ctaText}
              headline={data.headline}
              subheadline={data.subheadline}
              variant={data.variant}
            />
          </>
        )}
      </div>
    </section>
  );
}
