import Image from 'next/image';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data?: {
    _type: string;
    bgColor: string;
    title: string;
    textColor: string;
    image: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  };
};

export default function Headline({ data }: Props): JSX.Element {
  return (
    <div
      className={`relative flex flex-col justify-center md:flex-row w-full pt-6 pb-8 ${data?.bgColor ?? 'bg-white'}`}
    >
      {data?.image ? (
        <div className="relative w-full h-[7.5rem] md:h-[10rem] lg:h-[13rem]">
          <Image
            alt={data.title}
            blurDataURL={data.image.lqip}
            fill
            placeholder="blur"
            priority
            sizes="100vw"
            src={urlForImage(data.image).url()}
            style={{
              objectFit: 'cover',
            }}
          />
        </div>
      ) : null}
      <div className="flex justify-center items-center">
        <h1
          className={`text-2xl md:text-3xl lg:text-[2.375rem] leading-[2.875rem] font-bold ${data?.textColor ?? 'text-white'}`}
        >
          {data?.title}
        </h1>
      </div>
    </div>
  );
}
