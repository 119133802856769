'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import Image from 'next/image';
import 'swiper/css';
import 'swiper/css/pagination';
import { PortableText } from '@portabletext/react';
import Button from '@mui/material/Button';
import { urlForImage } from '@lib/sanity/image';
import type { HeroItem } from './complex-hero';

type HerosCarouselProps = {
  heros?: HeroItem[];
};

export default function HeroCarousel({ heros }: HerosCarouselProps): JSX.Element {
  if (!heros || heros.length === 0) {
    return <div className="hidden">No data have been associated to this component</div>;
  }

  return (
    <Swiper
      breakpoints={{
        520: {
          pagination: {
            enabled: true,
          },
          slidesPerView: 1,
        },
        1040: {
          pagination: {
            enabled: true,
          },
          slidesPerView: 1,
        },
        1300: {
          pagination: {
            enabled: true,
          },
          slidesPerView: 1,
        },
      }}
      className="max-w-full"
      loop={false}
      modules={[Pagination]}
      pagination={{
        el: '.swiper-custom-pagination',
        enabled: true,
        clickable: true,
      }}
      slidesPerView={1}
    >
      {heros.map((hero) => {
        const hasData = hero.headline || hero.largeImage.asset._ref || hero.mobileImage.asset._ref;
        if (!hasData) {
          return null;
        }

        const largeImageUrl = urlForImage(hero.largeImage).url();
        const mobileImageUrl = urlForImage(hero.mobileImage).url();
        return (
          <SwiperSlide key={hero._key}>
            <div className="flex justify-center">
              <div className="flex flex-col lg:flex-row justify-center max-w-screen-lg">
                <div className="flex flex-col justify-center w-full lg:w-1/2 h-full pr-0 lg:pr-16 mb-4 lg:mb-0">
                  <h2 className="w-full text-2xl lg:text-[2.375rem] leading-[1.875rem] lg:leading-[2.875rem] font-bold line-clamp-4 text-primary text-center lg:text-start">
                    {hero.headline}
                  </h2>
                  <div className="pb-0 sm:pb-3 lg:pb-5 pt-4 lg:pt-5 text-sm md:text-base text-center lg:text-start text-black-base font-medium">
                    <PortableText value={hero.subheadline} />
                  </div>
                  <div className="hidden sm:inline md:inline lg:inline self-start sm:self-center md:self-center lg:self-start">
                    <Button
                      className="text-base font-medium"
                      color="primary"
                      disableElevation
                      href={hero.ctaHref}
                      variant="contained"
                    >
                      {hero.ctaText}
                    </Button>
                  </div>
                </div>
                <div className="flex flex-col justify-center w-full lg:w-1/2 h-full">
                  <picture className="w-full h-full rounded-md">
                    <source media="(max-width: 960px)" srcSet={mobileImageUrl} />
                    <source media="(min-width: 1200px)" srcSet={largeImageUrl} />
                    <div className="relative w-full h-full min-h-[12rem] sm:min-h-[26.5rem] ">
                      <Image
                        alt={hero.headline}
                        blurDataURL={hero.largeImage.lqip}
                        className="w-full rounded-md"
                        fill
                        placeholder="blur"
                        priority
                        sizes="100vw"
                        src={largeImageUrl}
                        style={{
                          objectFit: 'cover',
                        }}
                      />
                    </div>
                  </picture>
                </div>
                <div className="inline sm:hidden md:hidden lg:hidden mt-4 w-full">
                  <Button
                    className="w-full text-base font-medium text-center"
                    color="primary"
                    disableElevation
                    href={hero.ctaHref}
                    variant="contained"
                  >
                    {hero.ctaText}
                  </Button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}
