import Image from 'next/image';
import { PortableText } from '@portabletext/react';
import { urlForImage } from '@lib/sanity/image';

type Props = {
  data: {
    _type: string;
    headline: string;
    subheadline: [];
    largeImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
    mobileImage: {
      asset: {
        _type: string;
        _ref: string;
      };
      lqip: string;
    };
  };
};

export default function Hero({ data }: Props): JSX.Element | null {
  const hasImage = data.headline || data.largeImage.asset._ref || data.mobileImage.asset._ref;
  if (!hasImage) {
    return null;
  }

  const largeImageUrl = urlForImage(data.largeImage).url();
  const mobileImageUrl = urlForImage(data.mobileImage).url();

  return (
    <section className="relative flex flex-col justify-center md:flex-row w-full bg-accent-3">
      <div className="relative w-full h-[50vw] min-h-[15rem] max-h-[38rem] md:ml-auto">
        <picture className="block relative w-full h-full">
          <source media="(max-width: 960px)" srcSet={mobileImageUrl} />
          <source media="(min-width: 1200px)" srcSet={largeImageUrl} />
          <Image
            alt={data.headline}
            blurDataURL={data.largeImage.lqip}
            fill
            placeholder="blur"
            priority
            sizes="100vw"
            src={largeImageUrl}
            style={{
              objectFit: 'cover',
            }}
          />
        </picture>
      </div>
      <div className="md:absolute w-full max-w-screen-xl min-h-[11rem] md:h-[100%] md:top-0 px-4 py-6 md:pt-20 md:pb-16 md:pr-8 lg:pr-16 xl:pr-0">
        <div className="flex flex-col justify-center w-full h-full md:pl-[50%]">
          <h2 className="w-full text-fluid-lg leading-[2rem] md:leading-[3rem] font-bold line-clamp-3 text-primary md:text-white">
            {data.headline}
          </h2>
          <div className="pt-6 pb-2 md:py-4 text-lg text-black md:text-white font-medium leading-6">
            <PortableText value={data.subheadline} />
          </div>
        </div>
      </div>
    </section>
  );
}
