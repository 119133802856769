import HeroCarousel from './hero-carousel';

export type HeroItem = {
  _key: string;
  title: string;
  headline: string;
  subheadline: [];
  largeImage: {
    asset: {
      _type: string;
      _ref: string;
    };
    lqip: string;
  };
  mobileImage: {
    asset: {
      _type: string;
      _ref: string;
    };
    lqip: string;
  };
  ctaText?: string;
  ctaHref?: string;
};

type Props = {
  data: {
    _type: string;
    name: string;
    heroItem?: HeroItem[];
  };
};

export default function ComplexHero({ data }: Props): JSX.Element | null {
  if (!data.heroItem || data.heroItem.length === 0) {
    return null;
  }
  return (
    <>
      <section className="flex w-full justify-center bg-accent-3 px-6 md:px-10 lg:px-16 py-5 sm:py-8 lg:py-16">
        <HeroCarousel heros={data.heroItem} />
      </section>

      <div className="swiper-custom-pagination text-center gap-2 pb-5 pt-0 md:pt-5 bg-accent-3" />
    </>
  );
}
