'use client';

import { createElement } from 'react';
import type { TranslationsDictionary } from '@lib/i18n';
import Breadcrumbs from './breadcrumbs';
import Faqs from './faqs';
import FeaturedProducts from './featured-products';
import Headline from './headline';
import Hero from './hero';
import StoreLocator from './store-locator';
import TextAndImage from './text-and-image';
import TextBlock from './text-block';
import RecommendedBy from './recommended-by';
import Ingredients from './ingredients';
import ReviewSmall from './review-small';
import Review from './review';
import complexHero from './complex-hero';
import GroupedFaqs from './grouped-faqs';
import Contact from './contact';

type ComponentProps = {
  data: {
    _type: string;
  };
  locale: string;
  translationsDictionary: TranslationsDictionary;
  slug?: string | string[];
};

const componentTypes = {
  reviewSmall: ReviewSmall,
  breadcrumbs: Breadcrumbs,
  faqs: Faqs,
  featuredProducts: FeaturedProducts,
  headline: Headline,
  complexHero,
  hero: Hero,
  storeLocator: StoreLocator,
  textAndImage: TextAndImage,
  textBlock: TextBlock,
  recommendedBy: RecommendedBy,
  ingredients: Ingredients,
  review: Review,
  groupedFaqs: GroupedFaqs,
  contact: Contact,
};

export default function Component({ data, locale, translationsDictionary, slug }: ComponentProps): JSX.Element | null {
  // @ts-expect-error -- this would require a type for each component, and we don't need that complexity
  const component = componentTypes[data._type];

  if (!component) {
    return null;
  }

  return createElement(component, {
    data,
    locale,
    translationsDictionary,
    slug,
  });
}
