import Link from 'next/link';
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { locales } from '@lib/i18n/locales';

type Props = {
  data?: {
    bgColor?: string;
    items: {
      label: string;
      href?: string;
    }[];
  };
  locale: string;
};

export default function Breadcrumbs({ data, locale }: Props): JSX.Element | null {
  const breadcrumbs = data?.items ?? [];
  const currentLocale = locales.find((localeElement) => localeElement.path === locale);
  const homeURL = currentLocale?.domain ? '/' : `/${currentLocale?.path}`;

  if (!data) {
    return null;
  }

  return (
    <div className={`md:flex w-full justify-center px-6 md:px-10 py-3 lg:px-16 ${data.bgColor ?? 'bg-white'}`}>
      <div className="flex flex-wrap justify-start items-center lg:flex-nowrap w-full max-w-screen-lg">
        <MuiBreadcrumbs
          aria-label="breadcrumb"
          className="text-[0.813rem] md:text-[0.875rem] leading-[1.625rem] md:leading-5 font-medium text-[#404040]"
          separator={<NavigateNextIcon className="w-4 h-4" />}
        >
          <Link className="text-[0.875rem] leading-5 font-medium text-[#404040]" href={homeURL}>
            Home
          </Link>
          {breadcrumbs.map((item, index) => {
            return breadcrumbs.length === index ? (
              <Link
                className="text-[0.875rem] leading-5 font-medium text-[#404040]"
                href={item.href ?? '/'}
                key={item.label}
              >
                Home
              </Link>
            ) : (
              <p key={item.label}>{item.label}</p>
            );
          })}
        </MuiBreadcrumbs>
      </div>
    </div>
  );
}
